import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../layouts'
// import DEBUG from '../../utils/debug'
import Moment from 'react-moment'
import ads1 from '../../images/sample-ad-2.gif'
import ads2 from '../../images/sample-ad-3.jpeg'

export default ({ data }) => {
  const posts = data.allStrapiPosts.edges.map(post => post.node)
  const tagPostID = data.strapiTags.posts.map(post => {
    return post.id
  })

  let banners = []

  tagPostID.forEach(id => {
    banners.push(
      posts.find(post => {
        return post.id === id
      })
    )
  })

  return (
    <Layout
      head={{
        title: data.strapiTags.name,
      }}
    >
      <div className="banner-background">
        <center>
          <h1>{data.strapiTags.name} Tag(s)</h1>
        </center>
      </div>
      <div className="news-list-section">
        <div className="container">
          <ul className="m-0">
            <div className="row">
              <div className="col-md-8">
                {banners.map(banner => (
                  <div className="row news-item">
                    <div key={banner.id} className="col-md-5">
                      <Link to={banner.fields.slug}>
                        <div className="overlay">
                          <div className="thumb-date post-date" />
                        </div>
                      </Link>
                      <Link to={banner.fields.slug}>
                        <div
                          className="news-list-image"
                          style={{
                            backgroundImage: `url(${banner.banner.url})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                          }}
                        >
                          <div className="overlay">
                            <div className="thumb-date post-date">
                              <span className="month">
                                <Moment format="MMM" date={banner.published} />
                              </span>
                              <span className="day">
                                <Moment format="DD" date={banner.published} />
                              </span>
                              <span className="year">
                                <Moment format="YYYY" date={banner.published} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-md-7">
                      <Link to={banner.fields.slug} className="news-headline">
                        <h4>{banner.title}</h4>
                      </Link>
                      <p>{banner.excerpt}</p>
                      <Link to={banner.fields.slug} className="arrow-link">
                        Read More
                        <i className="fas fa-long-arrow-alt-right" />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-md-4">
                <span className="headline-span">Trending Now</span>
                {posts.map(post => (
                  <div>
                    {post.tags.map(tag => (
                      <div>
                        {tag.name === 'Featured' ? (
                          <div className="row trending-now-item">
                            <div key={post.id} className="col-md-5">
                              <Link to={post.fields.slug}>
                                <div
                                  className="trending-now-image"
                                  style={{
                                    backgroundImage: `url(${post.banner.url})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                  }}
                                >
                                  <div className="date-trending">
                                    <span className="date">
                                      <Moment
                                        format="MMM DD"
                                        date={post.published}
                                      />
                                      <i className="fas fa-long-arrow-alt-right" />
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-7">
                              <Link
                                to={post.fields.slug}
                                className="news-headline"
                              >
                                <h4>{post.title}</h4>
                              </Link>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                ))}

                <div className="ads-img">
                  <img
                    src={ads1}
                    className="mb-4 img-fluid"
                    alt="First advertisement"
                  />
                  <img
                    src={ads2}
                    className="mb-4 img-fluid"
                    alt="Second advertisement"
                  />
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allStrapiPosts(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          createdAt
          updatedAt
          fields {
            slug
          }
          banner {
            url
          }
          author {
            id
          }
          tags {
            id
            name
          }
          published
        }
      }
    }

    strapiTags(id: { eq: $id }) {
      id
      name
      posts {
        id
        title
        excerpt
        published
      }
    }
  }
`
